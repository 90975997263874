@tailwind base;
@tailwind components;
@tailwind utilities;


.main-image{
  /* position: relative; */
  background: url(banner.png) no-repeat center;
  background-size: cover;
  
  overflow: hidden;
}
.footer-image {
  position: relative;
  background: url(banner.png) no-repeat center;
  background-size: cover;
  
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.pagination {
  display: flex;
}

.pagination li {
  margin: 0 5px;
  padding: 0 5px;
  border-radius: 20px;
}

.pagination li:hover {
  background-color: lightgray;
}

.pagination li.active {
  color: #00B061;
}